<template>
  <!-- 这是主页 -->
  <div>
    <div v-if="true" class="top" style="margin-top:20px; flex-direction: column; align-items: flex-start; ">
      <div style=" display: flex;align-items: center; margin-bottom: 15px;">
        <div class="ml">
          <span style="margin-right: 10px">订单金额统计</span>
          <el-date-picker
            style="width: 353px"
            :clearable="false"
            :disabled="!search"
            v-model="chaxunTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div class="ml">
          <span style="margin: 0 10px 0px 30px">场地</span>

          <el-select v-model="address_id" filterable placeholder="全部" :disabled="!search">
            <el-option
              v-for="item in new_add_address"
              :key="item.id"
              :label="item.address_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <el-button type="primary" style="margin-left: 10px" @click="chaxuns()" v-if="search">查询</el-button>
        <el-button
          type="warning"
          style="margin-left: 10px"
          @click="
            search = true;
            lissssdata = null;
            chaxunTime = null;
          "
          v-else
          >取消搜索</el-button
        >
      </div>

      <el-descriptions v-if="lissssdata" class="margin-top" :column="2" border>
        <el-descriptions-item label="充值余额金额">
          <div style="padding: 0 30px;font-weight: bold;">{{ lissssdata.de }}</div>
        </el-descriptions-item>
        <el-descriptions-item label="统计金额">
          <div style="padding: 0 30px;font-weight: bold;">{{ lissssdata.order_amount }}</div>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div style="display:flex;justify-content: space-between">
      <div class="top">
        <!-- 圆环 -->
        <div class="yuan_box">
          <div id="main" :style="{ width: '300px', height: '300px' }"></div>
          <div class="text">
            <div>{{ option.series[0].data[0].value + option.series[0].data[1].value }}</div>
            <div>今日总收益</div>
          </div>
        </div>

        <div class="lis">
          <span>金额</span>
          <br />
          <span>{{ option.series[0].data[0].value }}元</span>
          <br />
          <span></span>
          <span>{{ option.series[0].data[1].value }}元</span>
        </div>
        <div class="lis">
          <span>交易笔数</span>
          <br />
          <span>{{ today_money_count }}笔</span>
          <br />
          <span></span>
          <span>{{ today_rjf_points_count }}笔</span>
        </div>
        <div class="lis">
          <span>笔单价</span>
          <br />
          <span>{{ bitotalnew }}元</span>
          <br />
          <span></span>
          <span>{{ bitotalold }}元</span>
        </div>
        <div class="lis zaixian">
          <span style="font-size:18px">实时在线率</span>
          <div style="margin:15px 0 30px">{{ inline }}</div>
          <div class="shuju">
            <div>
              <span>设备总数</span>
              <br />
              <span>{{ device_count }}</span>
            </div>
            <div>
              <span>离线</span>
              <br />
              <span>{{ device_not_use_count }}</span>
            </div>
          </div>
        </div>
        <div class="lis zaixian" style="padding:0;padding:20px 10px;width:178px !important">
          <span style="font-size:18px">所有用户数</span>
          <div style="margin:15px 0 30px">{{ alluser }}</div>
          <div class="shuju">
            <div style="width:80px">
              <span>当日使用数</span>
              <br />
              <span>{{ dateuse }}</span>
            </div>
            <div>
              <span>有效套餐数</span>
              <br />
              <span>{{ intaocannum }}</span>
            </div>
          </div>
        </div>
      </div>

      <div style="display:flex">
        <div class="lis zaixian right1" style="height:197px">
          <span style="font-size:18px">近90天顾客</span>
          <div style="margin:15px 0 30px">{{ user_90_day_in }}</div>
          <div class="shuju">
            <div>
              <span>新客户</span>
              <br />
              <span style="color:#5AFC02;line-height:60px">{{ user_90_day_in - old_user }}</span>
            </div>
            <div>
              <span>老客户</span>
              <br />
              <span style="line-height:60px">{{ old_user }}</span>
            </div>
          </div>
        </div>
        <div class="right2">
          <div class="right2_lis">
            <span class="name">用户数</span>
            <div class="inline_box">
              <div class="inline"></div>
              <div class="box_new_old">
                <div class="new" :style="{ width: newuser }"></div>
                <div class="old" :style="{ width: olduser }"></div>
              </div>
              <div class="flex">
                <span>{{ newuser == "NaN%" ? "0%" : newuser }}</span>
                <span>{{ olduser == "NaN%" ? "0%" : olduser }}</span>
              </div>
            </div>
          </div>
          <div class="right2_lis">
            <span class="name">交易金额</span>
            <div class="inline_box">
              <div class="inline"></div>
              <div class="box_new_old">
                <div class="new" :style="{ width: new_money_total }"></div>
                <div class="old" :style="{ width: old_money_total }"></div>
              </div>
              <div class="flex">
                <span>{{ new_money_total == "NaN%" ? "0%" : new_money_total }}</span>
                <span>{{ old_money_total == "NaN%" ? "0%" : old_money_total }}</span>
              </div>
            </div>
          </div>
          <div class="right2_lis">
            <span class="name">交易笔数</span>
            <div class="inline_box">
              <div class="inline"></div>
              <div class="box_new_old">
                <div class="new" :style="{ width: new_user_order_count }"></div>
                <div class="old" :style="{ width: old_user_order_count }"></div>
              </div>
              <div class="flex">
                <span>{{ new_user_order_count == "NaN%" ? "0%" : new_user_order_count }}</span>
                <span>{{ old_user_order_count == "NaN%" ? "0%" : old_user_order_count }}</span>
              </div>
            </div>
          </div>
          <div class="bottom_box">
            <div class="flex" style="margin-right:32px">
              <div class="color color1"></div>
              <div class="guke">新顾客</div>
            </div>
            <div class="flex">
              <div class="color color2"></div>
              <div class="guke">老顾客</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottombox">
      <div class="left">
        <div class="qushi">近30天场地收益</div>
        <div id="zhuzhuang" :style="{ width: '800px', height: '500px' }"></div>
      </div>
      <div class="right">
        <div class="qushi">近30天支付趋势</div>
        <div id="zhexian" :style="{ width: '500px', height: '400px' }" style="margin-top:30px"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { indextotal, srchses, addressList } from "../request/api";
import { ElMessage } from "element-plus";
import * as echarts from "echarts";
import { number } from "echarts";
export default {
  data() {
    // 圆形表单
    let option = {
      color: ["#4cabce", "#e5323e"],
      tooltip: {
        trigger: "item",
      },
      legend: {
        // left: "center",
        orient: "vertical", //设置图例的方向
        right: 0,
        top: "center",
        itemGap: 50, //设置图例的间距
      },
      series: [
        {
          center: ["30%", "50%"],
          name: "访问来源",
          type: "pie",
          radius: ["40%", "50%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "left",
          },
          emphasis: {
            label: {
              fontSize: "20",
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: [
            { value: 0, name: "套餐收益" },
            { value: 0, name: "储值收益" },
          ],
        },
      ],
    };
    // 折现表单
    let option2 = {
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: ["现金数", "余额数", "订单总数"],
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: [],
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "现金数",
          type: "line",
          stack: "总量",
          data: [],
        },
        {
          name: "余额数",
          type: "line",
          stack: "总量",
          data: [],
        },
        {
          name: "订单总数",
          type: "line",
          stack: "总量",
          data: [],
        },
      ],
    };
    // 柱状表单
    let option3 = {
      dataset: [
        {
          dimensions: ["name", "money"],
          source: [],
        },
        {
          transform: {
            type: "sort",
            config: { dimension: "money", order: "desc" },
          },
        },
      ],
      xAxis: {
        type: "category",
        axisLabel: { interval: 0, rotate: 30 },
      },
      yAxis: {},
      series: {
        type: "bar",
        encode: { x: "name", y: "score" },
        datasetIndex: 1,
      },
    };
    return {
      new_add_address: [],
      address_id: null,
      search: true,
      chaxunTime: "",
      lissssdata: null,

      alluser: 0,
      dateuse: 0,
      intaocannum: 0,
      option,
      option2,
      option3,
      totalMoney: 0,
      today_rjf_points_count: "",
      today_money_count: "",
      device_not_use_count: "",
      device_count: "",
      inline: 0,
      user_90_day_in: "",
      newuser: "",
      olduser: "",
      new_user_order_count: "",
      old_user_order_count: "",
      new_money_total: "",
      old_money_total: "",
      type: "",
    };
  },
  computed: {
    // 套餐笔单价
    bitotalnew() {
      if (this.today_money_count == 0 || this.option.series[0].data[0].value == 0) {
        return 0;
      } else {
        return (this.option.series[0].data[0].value / this.today_money_count).toFixed(2);
      }
    },
    // 储值笔单价
    bitotalold() {
      if (this.today_rjf_points_count == 0 || this.option.series[0].data[1].value == 0) {
        return 0;
      } else {
        return (this.option.series[0].data[1].value / this.today_rjf_points_count).toFixed(2);
      }
    },
  },

  mounted() {},
  async created() {
    console.log(addressList);
    addressList({
      page: 1,
      page_size: 999,
    }).then((res) => {
      console.log("地址", res);
      this.new_add_address = res.list.address_list;
    });
    let val = await this.info();
    this.$store.commit("pagination");
    if (val == true) {
      console.log(4568552);
      let myChart = echarts.init(document.getElementById("main"));
      let myChartZxian = echarts.init(document.getElementById("zhexian"));
      let zhuzhuang = echarts.init(document.getElementById("zhuzhuang"));
      myChart.setOption(this.option);
      myChartZxian.setOption(this.option2);
      console.log("柱状", this.option3);
      zhuzhuang.setOption(this.option3);
    }
  },
  methods: {
    chaxuns() {
      console.log("查询");
      console.log(this.chaxunTime);
      if (!this.chaxunTime) {
        ElMessage.warning("请先选择时间范围");
        return;
      }
      this.search = false;

      srchses({
        time1: this.chaxunTime[0],
        time2: this.chaxunTime[1],
        address_id: this.address_id,
      }).then((res) => {
        this.lissssdata = res.list;
        console.log("筛选时间", res);
      });
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      // var h = date.getHours() + ":";
      // var m = date.getMinutes() + ":";
      // var s = date.getSeconds();
      return Y + M + D;
    },
    info() {
      return new Promise((resolve, reject) => {
        indextotal().then((res) => {
          if (res.code == 200) {
            resolve(true);
            let info = res.list;
            console.log("展示数据", info);
            this.alluser = info.total_users_count;
            this.dateuse = info.total_use_users_count;
            this.intaocannum = info.total_package_count;
            this.totalMoney = info.total_money;

            this.option.series[0].data[0].value = info.today_money;
            this.option.series[0].data[1].value = info.today_rjf_points;

            this.today_rjf_points_count = info.today_rjf_points_count;
            this.today_money_count = info.today_money_count;
            this.device_not_use_count = info.device_not_use_count;
            this.device_count = info.device_count;
            let aa = (this.device_not_use_count / this.device_count).toFixed(2) * 100 + "%";
            this.inline = this.device_not_use_count == 0 ? "100%" : aa;
            this.user_90_day_in = info.user_90_day_in;
            this.old_user = info.old_user;
            let newuser = ((info.user_90_day_in - info.old_user) / info.user_90_day_in) * 100;
            let olduser = (info.old_user / info.user_90_day_in) * 100;
            this.newuser = Math.floor(newuser) + "%";
            this.olduser = Math.ceil(olduser) + "%";
            var dateTime = Date.parse(new Date()) / 1000; //获取当前日期
            var dateList = [];
            //var NowDate = timestampToTime(dateTime );
            for (var i = 29; i >= 0; i--) {
              dateList[i] = this.timestampToTime(dateTime);
              dateTime = dateTime - 86400;
            }
            let dateListlist = [];
            dateList.forEach((item, index) => {
              let qq = {};
              qq.date = item;
              qq.order_amount_sum = 0;
              qq.rjf_points_sum = 0;
              qq.order_count = 0;
              dateListlist.push(qq);
            });
            let month = info.mouth_order;
            dateListlist.forEach((item) => {
              month.forEach((item2) => {
                if (item.date == item2.date) {
                  item2.date = item2.date.substring(5, 10);
                  this.option2.xAxis.data.push(item2.date);
                  this.option2.series[0].data.push(item2.order_amount_sum);
                  this.option2.series[1].data.push(item2.rjf_points_sum);
                  this.option2.series[2].data.push(item2.order_count);
                }
              });
            });

            dateListlist.forEach((item, index) => {
              item.date = item.date.substring(5, 10);
              if (item.date != this.option2.xAxis.data[index]) {
                this.option2.xAxis.data.splice(index, 0, item.date);
                this.option2.series[0].data.splice(index, 0, "0");
                this.option2.series[1].data.splice(index, 0, "0");
                this.option2.series[2].data.splice(index, 0, "0");
              }
            });
            let address = info.address.filter((el) => el.address_name);
            let nn = [];
            address.forEach((item, index) => {
              this.option3.dataset[0].source[index] = [];
              this.option3.dataset[0].source[index].push(item.address_name);
              let ff = item.mouth_order.reduce((current, pre) => {
                return Number(pre.order_amount_sum) + Number(pre.rjf_points_sum) + current;
              }, 0);
              this.option3.dataset[0].source[index].push(ff);
            });
            let total2 = info.new_user_order_count + info.old_user_order_count;
            let new_user_order_count = (info.new_user_order_count / total2) * 100;
            let old_user_order_count = (info.old_user_order_count / total2) * 100;
            this.new_user_order_count = Math.floor(new_user_order_count) + "%";
            this.old_user_order_count = Math.ceil(old_user_order_count) + "%";
            let total =
              info.new_user_order_price_sum +
              info.new_user_order_points_sum +
              info.old_user_order_price_sum +
              info.old_user_order_points_sum;
            let vv = ((info.new_user_order_price_sum + info.new_user_order_points_sum) / total) * 100;
            let mm = ((info.old_user_order_price_sum + info.old_user_order_points_sum) / total) * 100;
            this.new_money_total = Math.floor(vv) + "%";
            this.old_money_total = Math.ceil(mm) + "%";
            console.log(1234);
          }
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.bottombox {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  .qushi {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
  }

  .left {
    width: 1018px;
  }

  .right {
    width: 600px;
    height: 500px;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    padding: 22px 37px;
    box-sizing: border-box;
  }
}

.right1 {
  border-radius: 10px 0px 0px 10px !important;
}

.right2 {
  .bottom_box {
    float: right;
    display: flex;

    .guke {
      font-size: 12px;
      font-weight: 400;
      color: #333333;
    }

    .color {
      width: 14px;
      height: 14px;
      margin-right: 12px;
    }

    .color1 {
      background: #00ff00;
    }

    .color2 {
      background: #ff0000;
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    align-items: center;
  }

  .right2_lis {
    margin-bottom: 14px;
    display: flex;
    justify-content: space-between;

    .name {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }

    .inline_box {
      width: 300px;

      .inline {
        width: 100%;
        height: 4px;
        background: #d4d4d4;
      }

      position: relative;

      .box_new_old {
        width: 100%;
        position: absolute;
        top: 0;
        display: flex;
        justify-content: space-between;

        .new {
          height: 4px;
          background-color: #00ff00;
        }

        .old {
          height: 4px;
          background-color: #ff0000;
        }
      }
    }
  }

  padding: 20px 26px;
  box-sizing: border-box;
  width: 434px;
  height: 197px;
  border: 1px solid #eeeeee;
  border-radius: 0px 10px 10px 0px;
  margin-top: 40px;
}

.zaixian {
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  width: 150px !important;
  height: 190px;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  margin-top: 40px !important;

  .shuju {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.top {
  display: flex;

  .lis {
    font-size: 15px;
    font-weight: 400;
    color: #333333;
    // margin-left: 30px;
    margin-top: 70px;
    text-align: center;
    width: 80px;

    span:nth-of-type(2) {
      display: block;
      line-height: 60px;
      font-size: 14px;
    }

    span:nth-of-type(3) {
      display: block;
      width: 30px;
      height: 2px;
      background: #f2f2f2;
      display: block;
      margin: 0 auto;
    }

    span:nth-of-type(4) {
      display: block;
      line-height: 70px;
      font-size: 14px;
    }
  }
}

.yuan_box {
  position: relative;
  width: 300px;
  height: 300px;

  .text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 30%;
    transform: translateY(-50%) translateX(-50%);

    div:nth-of-type(1) {
      color: red;
    }

    div:nth-of-type(2) {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }

    div {
      line-height: 30px;
    }
  }
}
</style>
